@import 'colors';

.common {
  padding: 8px 24px;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.common svg{
  color: $PRIMARY_TEXT;
  margin-right: 6px;
}

.label {
  font-family: "Proxima Nova", sans-serif;
  font-size: 12px;
  margin-bottom: -2px;
}


// PRIMARY
.primary {
  background: $BLUE_GRADIENT;
  border: none;
}

.primary:hover {
  background: $BLUE_GRADIENT_HOVER;
}


// PRIMARY OUTLINED
.primary-outlined {
  background: transparent;
  border: 1px solid $PRIMARY;
  padding: 6px 22px;
}

.primary-outlined .label {
  color: $PRIMARY;
}

.primary-outlined:hover {
  box-shadow: 0 0 3px 0 $PRIMARY;
}

// DANGER
.danger {
  background: $RED_GRADIENT;
  border: none;
}

.danger:hover {
  background: $RED_GRADIENT_HOVER;
}


// DANGER OUTLINED
.danger-outlined {
  background: transparent;
  border: 1px solid $ERROR;
  padding: 6px 22px;
}

.danger-outlined .label {
  color: $ERROR;
}

.danger-outlined:hover {
  box-shadow: 0 0 3px 0 $ERROR;
}


// TEXT
.text {
  background: transparent;
  border: none;
}

.text .label {
  color: $PRIMARY;
}

.text:hover .label {
  font-weight: 600;
}


// TEXT
.danger-text {
  background: transparent;
  border: none;
}

.danger-text .label {
  color: $RED_GRADIENT;
}

.danger-text:hover .label {
  font-weight: 600;
}

