@import './colors';

.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
}

.modal {
    position: fixed;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $SECONDARY_BACKGROUND;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    transition: margin-top, 1s;
    z-index: 10001;
    max-width: 280px;
    box-shadow: 0 0 40px 15px rgba(0, 0, 0, 0.7);
    border: 1px solid $BORDER;
}

.modal p {
    color: $SECONDARY_TEXT;
    font-size: 14px;
    letter-spacing: 0.05em;
    text-align: center;
    margin-bottom: 18px;
}

.modal button {
    flex: 1;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    gap: 24px
}
