@import 'colors';

.page {
  background-color: $PRIMARY_BACKGROUND;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.page img {
  position: fixed;
  top: 18px;
  left: 18px;
  height: 60px;
}

.content {
  background-color: $SECONDARY_BACKGROUND;
  padding: 48px;
  border: 1px solid $BORDER;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
  margin-top: 150px;
}

.content > svg{
  font-size: 69px;
  color: $PRIMARY;
  margin-bottom: 18px;
}

.title {
  color: $SECONDARY_TEXT;
  font-size: 14px;
  margin-bottom: 24px;
  text-align: center;
  line-height: 1.3em;
}

.subTitle {
  color: $SECONDARY_TEXT;
  font-size: 14px;
}

.rotate {
  font-size: 60px;
  animation: rotation 1.5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}