$PRIMARY: #29B7FF;

$PRIMARY_TEXT: #FFFFFF;
$SECONDARY_TEXT: #B1BED1;
$TERTIARY_TEXT: #565F6A;

$PRIMARY_TEXT_DARK: #222;
$SECONDARY_TEXT_DARK: #444;
$TERTIARY_TEXT_DARK: #666;

$PLACEHOLDER: #565F6A;
$PLACEHOLDER_LIGHT: #13151C10;

$ERROR: #DD4242;
$ERROR_BACKGROUND: #ffc3c3;

$SUCCESS: #2ebd60;
$ERROR_BACKGROUND: #c3ffdf;

$PRIMARY_BACKGROUND: #000000;
$SECONDARY_BACKGROUND: #0e1319;
$TERTIARY_BACKGROUND: #1A232F;
$PRIMARY_BACKGROUND_LIGHT: #ffffff;
$SECONDARY_BACKGROUND_LIGHT: #eaecef;

$BORDER: #1A232F;
$BORDER_ACTIVE: #1d83b6;

$GRAY_LIGHT: #666;
$GRAY_DARK: #333;

$BLUE_GRADIENT: linear-gradient(180deg, #006CCD 0%, #29B7FF 100%);
$BLUE_GRADIENT_HOVER: linear-gradient(180deg, #0077E3 0%, #55C6FF 100%);

$RED_GRADIENT: linear-gradient(180deg, #cd0000 0%, #ff7676 100%);
$RED_GRADIENT_HOVER: linear-gradient(180deg, #e10000 0%, #ff8b8b 100%);