@import 'colors';

.common {
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color, 0.5s;
}

.common svg{
  color: $PRIMARY_TEXT;
  transition: color, 0.2s;
}

// PRIMARY
.primary {
  background: $BLUE_GRADIENT;
}

.primary:hover {
  background: $BLUE_GRADIENT_HOVER;
}


// PRIMARY
.secondary {
  background: $SECONDARY_BACKGROUND;
}

.secondary:hover {
  background: $TERTIARY_BACKGROUND;
}

.secondary:active svg{
  color: $PRIMARY;
}


