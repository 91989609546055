@import 'colors';

.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $BORDER;
  padding: 12px;
  background-color: $PRIMARY_BACKGROUND;
  flex: 1;
  justify-content: space-between;
}

.headerInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.header svg{
  color: $SECONDARY_TEXT;
  font-size: 18px;
}

.list {
  display: flex;
  flex-direction: column;
}

.listItem {
  padding: 18px 14px;
  border-bottom: 1px solid $BORDER;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.listItemLabel {
  flex: 1;
}

.listItem svg{
  color: $SECONDARY_TEXT;
}
.sectionHeader {
  padding: 12px;
  border-bottom: 1px solid $BORDER;
  background-color: $SECONDARY_BACKGROUND;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color, 0.5s;
}

.sectionHeader:hover {
  background-color: $SECONDARY_BACKGROUND;
}

.sectionHeader span{
  font-size: 12px;
  color: $SECONDARY_TEXT;
}

.sectionHeader svg{
  font-size: 18px;
  color: $PRIMARY_TEXT;
}

.notExpandedSvg {
  transform: rotate(0deg);
  color: $SECONDARY_TEXT;
  transition: transform, 0.5s;
}

.expandedSvg {
  transform: rotate(90deg);
  color: $PRIMARY !important;
  transition: transform, 0.5s;
}

.expandedText {
  color: $PRIMARY !important;
  transition: color, 0.5s;
}