@import 'colors';

.wrapper {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 100px 0;
  z-index: 10;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out;
}

.wrapper.show {
  opacity: 1;
  visibility: visible;
}

.mask {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, .4);
  z-index: 11;
  cursor: default;
}

.modal {
  background-color: $SECONDARY_BACKGROUND;
  border: 1px solid $BORDER;
  display: flex;
  flex-direction: column;
  z-index: 12;
  min-width: 400px;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $BORDER;
  padding: 12px;
  background-color: $PRIMARY_BACKGROUND;
  cursor: default;
}

.header svg{
  color: $SECONDARY_TEXT;
  font-size: 18px;
}

.header .close{
  cursor: pointer;
}

.header .close:hover{
  color: $ERROR;
}

.list {
  display: flex;
  flex-direction: column;
}

.listItem {
  padding: 18px 14px;
  border-bottom: 1px solid $BORDER;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.listItemLabel {
  color: $SECONDARY_TEXT;
}

.listItem svg{
  color: $SECONDARY_TEXT;
}