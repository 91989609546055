@import 'colors';


.tag {
  background-color: $TERTIARY_BACKGROUND;
  padding: 4px 10px;
  border-radius: 12px;
}

.tag span {
  font-size: 10px;
  color: $PRIMARY;
}