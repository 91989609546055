@import "colors";

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 50px;
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.container svg{
    color: $PRIMARY;
    font-size: 18px;
    animation: spin 1.5s infinite linear;
}
