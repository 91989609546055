@import 'colors';

.container {
  position: fixed;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $SECONDARY_BACKGROUND_LIGHT;
  padding: 10px 24px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  transition: margin-top, 1s;
}

.visible{
  top: 28px !important;
}

.container span{
  color: $PRIMARY_TEXT_DARK;
  font-size: 14px;
  margin-bottom: -2px;
}

.container .error{
  color: $ERROR;
}

.container .success{
  color: $SUCCESS;
}

.container .info{
  color: $PRIMARY;
}