@import 'colors';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  border: 1px solid $BORDER;
}

.wrapper input {
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  flex: 1;
  padding: 12px;
  color: $SECONDARY_TEXT;
}

.wrapper input::placeholder {
  font-size: 12px;
}

.wrapper {
  border-left: none;
  border-bottom: none;
  border-top: 1px solid $BORDER;
}
