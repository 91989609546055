@import 'colors';

.circledNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  background-color: $SECONDARY_BACKGROUND;
  border: 1px solid $BORDER
}