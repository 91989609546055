@import 'colors';


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.container svg {
  font-size: 32px;
  margin-bottom: 6px;
  color: $SECONDARY_TEXT;
}

.label {
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px;
  color: $SECONDARY_TEXT;
}